<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :value="openMenu"
    left
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-list-item
        v-if="isSubMenu"
        class="d-flex justify-space-between"
        @click="openMenuAndClearSearch"
        v-on="on"
      >
        {{ name }}
      </v-list-item>
      <BaseButton
        v-else
        color="grayDark"
        :disabled="disabled"
        dark
        @click="openMenuAndClearSearch"
        v-on="on"
      >
        {{ name }}
        <v-icon right>
          mdi-menu-down
        </v-icon>
      </BaseButton>
    </template>
    <v-list style="width: 250px">
      <v-list-item
        v-if="isSubMenu && isSearchInputEnabled"
        class="sub-menu-list search-input"
      >
        <v-text-field
          v-model="searchValue"
          :label="$t('game.search')"
          prepend-icon="mdi-magnify"
          clearable
          @click:clear="clearSearchValue"
        />
      </v-list-item>
      <template v-for="(item, index) in filteredMenuItems">
        <sub-menu
          v-if="item.menu"
          :key="index"
          :is-offset-x="true"
          :is-offset-y="false"
          :is-open-on-hover="false"
          :is-sub-menu="true"
          :menu-items="item.menu"
          :name="item.name"
          :is-search-input-enabled="isSearchInputEnabled"
          class="sub-menu-list"
          @sub-menu-click="emitClickEvent"
        />
        <v-list-item
          v-else
          :key="index"
          class="sub-menu-list"
          @click="emitClickEvent(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'SubMenu',
  components: {
    BaseButton,
  },
  props: {
    name: { type: String, default: '' },
    menuItems: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    isSearchInputEnabled: { type: Boolean, default: false },
  },
  data: () => ({
    openMenu: false,
    searchValue: '',
  }),
  computed: {
    filteredMenuItems() {
      const searchValue = this.searchValue ? this.searchValue.toLowerCase() : '';

      return this.menuItems.filter((item) => {
        const itemName = item.name ? item.name.toLowerCase() : '';

        return itemName.includes(searchValue);
      });
    },
  },
  methods: {
    emitClickEvent(item) {
      this.$emit('sub-menu-click', item);
      this.openMenu = false;
    },
    clearSearchValue() {
      this.searchValue = '';
    },
    openMenuAndClearSearch() {
      this.searchValue = '';
      this.openMenu = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--grayDark) !important;
  cursor: not-allowed;
  pointer-events: all !important;
}
.v-menu__content {
  max-height: 600px;
  overflow-y: auto;
}

.search-input {
  background-color: #F7F7F7;
}
</style>
