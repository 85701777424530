import api from '@/plugins/axios/api';

const recruitmentProcessExportCandidates = async () => {
  const response = await api.get(
    'recruitment-processes/export-candidate-data',
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default recruitmentProcessExportCandidates;
