import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const recruitmentProcessExtendedExportCandidates = async ({
  gameId,
}) => {
  const response = await api.get('recruitment-processes/extended-export-candidate-data', {
    params: {
      gameId,
    },
    responseType: 'blob',
  });

  return response;
};

export default recruitmentProcessExtendedExportCandidates;
