import store from '@/store';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import GenerateFileLayout from '@/layouts/GenerateFileLayout';
import AnalyticsExport from '@/views/Dashboard/Export/AnalyticsExport';
import CandidateReport from '@/views/Dashboard/Export/CandidateReport';
import CandidateFiles from '@/views/Dashboard/Export/CandidateFiles';
import CandidatesExport from '@/views/Dashboard/Export/CandidatesExport';
import CandidatesExtendedExport from '@/views/Dashboard/Export/CandidatesExtendedExport';
import GameStagesExport from '@/views/Dashboard/Export/GameStagesExport';
import RecruitmentProcessExportCandidates from '@/views/Dashboard/Export/RecruitmentProcessExportCandidates';
import RecruitmentProcessExtendedExportCandidates from '@/views/Dashboard/Export/RecruitmentProcessExtendedExportCandidates';
import File from '@/views/Dashboard/Export/File';
import {
  ANALYTICS_EXPORT,
  LOGIN,
  DASHBOARD,
  FILE,
  DOWNLOAD,
  CANDIDATE_REPORT,
  CANDIDATES_EXPORT,
  CANDIDATES_EXTENDED_EXPORT,
  CANDIDATE_FILES,
  GAME_STAGE_EXPORT,
  RECRUITMENT_PROCESS_CANDIDATES_EXPORT,
  RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT,
} from '@/router/routes.names';
import { isAuthenticated } from '@/router/permissions';
import {
  CANDIDATE_SHOW,
  CANDIDATE_RECRUITMENT_PROCESS_DATA_EXPORT,
  CANDIDATE_RECRUITMENT_PROCESS_EXTENDED_DATA_EXPORT,
  GAME_SESSION_EVENT_LOG_INDEX,
  GAME_STAGE_DATA_EXPORT,
  RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA,
  RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA,
} from '@/router/permissions.names';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';

const authenticated = () => store.getters['auth/isLogged'];

const downloadRoutes = [
  {
    path: '/download',
    component: GenerateFileLayout,
    name: DOWNLOAD,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('layout/startPreloadingData');
      try {
        if (!authenticated() && to.name === DASHBOARD) {
          next({ name: LOGIN });

          return;
        }
        await store.dispatch('session/setCurrentRoute', {
          currentRoute: to.fullPath,
        });
        await store.dispatch('session/fetchSession', {
          includes: ['acl', 'client_scope', 'client'],
        });
        const client = await fetchClient({
          clientId: store.getters['session/clientId'],
          includes: ['options'],
        });
        await store.dispatch('session/setClient', client);

        next();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          next();

          return;
        }
        throw new Error(e);
      } finally {
        await store.dispatch('layout/stopPreloadingData');
      }
    },
    children: [
      {
        path: 'analytics-export',
        name: ANALYTICS_EXPORT,
        component: AnalyticsExport,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: GAME_SESSION_EVENT_LOG_INDEX,
        },
      },
      {
        path: 'candidate-report/:candidateId/:recruitmentProcessId',
        name: CANDIDATE_REPORT,
        component: CandidateReport,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: CANDIDATE_SHOW,
        },
      },
      {
        path: 'candidates-export',
        name: CANDIDATES_EXPORT,
        component: CandidatesExport,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: CANDIDATE_RECRUITMENT_PROCESS_DATA_EXPORT,
        },
      },
      {
        path: 'candidates-extended-export',
        name: CANDIDATES_EXTENDED_EXPORT,
        component: CandidatesExtendedExport,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: CANDIDATE_RECRUITMENT_PROCESS_EXTENDED_DATA_EXPORT,
        },
      },
      {
        path: 'recruitment-process-candidates-export',
        name: RECRUITMENT_PROCESS_CANDIDATES_EXPORT,
        component: RecruitmentProcessExportCandidates,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA,
        },
      },
      {
        path: 'recruitment-process-candidates-extended-export/:gameId',
        name: RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT,
        component: RecruitmentProcessExtendedExportCandidates,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA,
        },
      },
      {
        path: 'candidate-files/:candidateId/:recruitmentProcessId',
        name: CANDIDATE_FILES,
        component: CandidateFiles,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: CANDIDATE_SHOW,
        },
      },
      {
        path: 'file/:fileUrl',
        name: FILE,
        component: File,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: CANDIDATE_SHOW,
        },
      },
      {
        path: 'game-stages-export/:gameId',
        name: GAME_STAGE_EXPORT,
        component: GameStagesExport,
        beforeEnter: isAuthenticated,
        meta: {
          permissions: GAME_STAGE_DATA_EXPORT,
        },
      },
    ],
  },
];

export default downloadRoutes;
