<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("recruitment.title") }}
            </h5>
            <div class="table-heading__actions">
              <div
                v-if="hasExportAccess || hasExtendedExportAccess"
                class="table-heading__item"
              >
                <RecruitmentExportMenu
                  :games="games"
                  :has-export-access="hasExportAccess"
                  :has-extended-export-access="hasExtendedExportAccess"
                  @export-data="exportCandidateData"
                  @export-extended-data="exportCandidateExtendedData"
                />
              </div>
              <div class="table-heading__item">
                <BaseButton @click="goToAddRecruitmentProcess">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <TableFilter
          v-model="activeFilters"
          @change-filter="fetchRecruitmentProcesses"
        >
          <template #default="{ updateFilter }">
            <div class="table-filters">
              <TableSearch
                is-full-text-search
                @input="fetchRecruitmentProcesses"
              />
              <BaseSelect
                v-model="activeFilters.game_ids"
                :items="games"
                :label="$t('recruitment.games')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                multiple
                hide-details
                searchable
                clearable
                @change="updateFilter('game_ids', activeFilters.game_ids)"
              />
              <BaseSelect
                v-model="activeFilters.work_position_id"
                :items="workPositions"
                :label="$t('recruitment.work_position')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                hide-details
                searchable
                clearable
                @change="updateFilter('work_position_id', activeFilters.work_position_id)"
              />
              <BaseSelect
                v-model="activeFilters.tags_assigned"
                :items="tags"
                :label="$t('recruitment.tags')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                multiple
                hide-details
                searchable
                clearable
                @change="updateFilter('tags_assigned', activeFilters.tags_assigned)"
              />
            </div>
          </template>
        </TableFilter>
        <v-divider />
        <div class="table-filters-details">
          <TableFilter
            v-model="activeFilters"
            @change-filter="fetchRecruitmentProcesses"
          >
            <template #default="{ updateFilter }">
              <WorkDepartments
                v-model="activeFilters.work_department_id"
                :work-departments="workDepartments"
                :disabled="isLoading"
                :recruitment-processes-length="recruitmentProcessesLength"
                @change="updateFilter('work_department_id', activeFilters.work_department_id)"
              />
            </template>
          </TableFilter>
        </div>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('recruitment.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          is-expandable
          @change-page="fetchRecruitmentProcesses"
          @change-items-per-page="fetchRecruitmentProcesses"
          @change-sort="fetchRecruitmentProcesses"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.localized_name ? item.localized_name : "-" }}</td>
            <td>{{ item.work_position ? item.work_position.data.name : "-" }}</td>
            <td>{{ item.work_position_display_name ? item.work_position_display_name : "-" }}</td>
            <td v-if="item.managing_users.data.length">
              <List
                item-key="name"
                limit="2"
                :items="item.managing_users.data"
              />
            </td>
            <td v-else>
              -
            </td>
            <td v-if="item.tags.data.length">
              <RecruitmentTags
                item-key="name"
                :recruitment-process-id="item.id"
                :items="item.tags.data"
                @refetch-data="fetchCompleteData"
              />
            </td>
            <td v-else>
              -
            </td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :recruitment-process-id="item.id"
                :is-open="item.is_open"
                :registration-url="item.registration_url"
                @refetch-data="fetchCompleteData"
                @handle-delete="deleteRecruitmentProcess"
              />
            </td>
          </template>
          <template v-slot:expanded-item="{ item }">
            <div class="pt-4 pb-8">
              <p class="text-subtitle-2">
                {{ $t("recruitment.process_tools") }}:
              </p>
              <template v-if="item.stages.data.length">
                <RecruitmentStagesTags
                  item-key="stage_name"
                  :items="item.stages.data"
                />
              </template>
              <template v-else>
                -
              </template>
            </div>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { RECRUITMENT_PROCESS_CANDIDATES_EXPORT, RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT, RECRUITMENT_PROCESS_ADD } from '@/router/routes.names';
import { RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA, RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA } from '@/router/permissions.names';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY, HTTP_CONFLICT } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { ASC } from '@/components/Table/sort.types';
import fetchRecruitmentProcesses from '@/components/Dashboard/Recruitment/fetchRecruitmentProcesses';
import deleteRecruitmentProcess from '@/components/Dashboard/Recruitment/deleteRecruitmentProcess';
import fetchWorkPositions from '@/components/Dashboard/WorkPosition/fetchWorkPositions';
import fetchWorkDepartments from '@/components/Dashboard/WorkDepartment/fetchWorkDepartments';
import fetchGames from '@/components/Dashboard/Game/fetchGames';
import fetchTags from '@/components/Dashboard/Recruitment/fetchTags';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableFilter from '@/components/Table/TableFilter';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/Recruitment/Actions';
import List from '@/components/Dashboard/Partials/List/List';
import RecruitmentTags from '@/components/Dashboard/Recruitment/RecruitmentTags';
import RecruitmentStagesTags from '@/components/Dashboard/Recruitment/RecruitmentStagesTags';
import WorkDepartments from '@/components/Dashboard/Partials/Filters/WorkDepartments';
import RecruitmentExportMenu from '@/components/Dashboard/Recruitment/RecruitmentExportMenu';

export default {
  name: 'RecruitmentProcessList',
  components: {
    Preloader,
    BaseSelect,
    BaseButton,
    BaseTable,
    TableFilter,
    TableSearch,
    Actions,
    List,
    RecruitmentTags,
    RecruitmentStagesTags,
    WorkDepartments,
    RecruitmentExportMenu,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('recruitment.name'),
        },
        {
          value: 'work_position',
          text: this.$t('recruitment.work_position'),
        },
        {
          value: 'work_position_display_name',
          text: this.$t('recruitment.work_position_display_name'),
        },
        {
          value: 'recruiters',
          text: this.$t('recruitment.recruiters'),
          sortable: false,
        },
        {
          value: 'tags',
          text: this.$t('recruitment.tags'),
          sortable: false,
        },
        {
          value: 'created_at',
          text: this.$t('recruitment.created_at'),
          width: '150px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
        {
          value: 'expanded',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tags: [],
      workPositions: [],
      workDepartments: [],
      games: [],
      tableData: [],
      recruitmentProcessesLength: 0,
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      isExporting: false,
      sort: {
        column: 'name',
        direction: ASC,
      },
      activeFilters: {
        tags_assigned: [],
        work_position_id: null,
        work_department_id: null,
        game_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters('session', ['clientId', 'hasPermission']),
    hasExportAccess() {
      return this.hasPermission(RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA);
    },
    hasExtendedExportAccess() {
      return this.hasPermission(RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA);
    },
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchCompleteData();
    this.setRouteFilters();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    goToAddRecruitmentProcess() {
      this.$router.push({ name: RECRUITMENT_PROCESS_ADD });
    },
    async fetchCompleteData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.fetchRecruitmentProcesses(),
          this.fetchRecruitmentProcessTags(),
          this.fetchWorkPositions(),
          this.fetchWorkDepartments(),
          this.fetchGames(),
        ]);
      } finally {
        this.isLoading = false;
      }
    },
    setParams(params = {}) {
      return {
        includes: [
          'managing_users',
          'work_position',
          'tags',
          'stages.stage_name',
          'registration_url',
        ],
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchRecruitmentProcesses(params) {
      try {
        this.isLoading = true;
        const response = await fetchRecruitmentProcesses(this.setParams(params));
        const { data } = await fetchRecruitmentProcesses({
          limit: 99999,
        });
        this.recruitmentProcessesLength = data.length;
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRecruitmentProcessTags() {
      try {
        this.isLoading = true;
        const { data } = await fetchTags({
          sort: {
            column: 'name',
            direction: ASC,
          },
          limit: 99999,
          filters: {
            only_assigned: 1,
          },
        });
        this.tags = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkPositions() {
      try {
        this.isLoading = true;
        const { data } = await fetchWorkPositions({
          sort: {
            column: 'name',
            direction: ASC,
          },
          limit: 99999,
        });
        this.workPositions = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkDepartments() {
      try {
        this.isLoading = true;
        const { data } = await fetchWorkDepartments({
          includes: ['current_recruitment_processes_count'],
          sort: {
            column: 'name',
            direction: ASC,
          },
          limit: 99999,
        });
        this.workDepartments = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchGames() {
      try {
        this.isLoading = true;
        const { data } = await fetchGames({
          filters: {
            client_id: this.clientId,
          },
          sort: {
            column: 'name',
            direction: ASC,
          },
          limit: 99999,
        });
        this.games = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteRecruitmentProcess({ recruitmentProcessId }) {
      try {
        this.isLoading = true;
        await deleteRecruitmentProcess({ recruitmentProcessId });
        const elementIndex = this.tableData.findIndex(
          (recruitmentProcess) => recruitmentProcess.id === recruitmentProcessId,
        );
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async exportCandidateData() {
      try {
        const candidateDataRoute = this.$router.resolve({
          name: RECRUITMENT_PROCESS_CANDIDATES_EXPORT,
        });
        window.open(candidateDataRoute.href, '_blank');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async exportCandidateExtendedData(gameId) {
      try {
        const candidateDataRoute = this.$router.resolve({
          name: RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT,
          params: {
            gameId,
          },
        });
        window.open(candidateDataRoute.href, '_blank');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    setRouteFilters() {
      if (this.$route.query.filters?.tags_assigned) {
        this.activeFilters.tags_assigned = this.$route.query.filters?.tags_assigned;
      }
      if (this.$route.query.filters?.work_position_id) {
        this.activeFilters.work_position_id = this.$route.query.filters?.work_position_id;
      }
      if (this.$route.query.filters?.work_department_id) {
        this.activeFilters.work_department_id = this.$route.query.filters?.work_department_id;
      }
      if (this.$route.query.filters?.game_ids) {
        this.activeFilters.game_ids = this.$route.query.filters?.game_ids;
      }
    },
  },
};
</script>
