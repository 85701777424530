export const ANALYTICS_EXPORT = 'ANALYTICS_EXPORT';

export const CANDIDATE_LIST = 'CANDIDATE_LIST';
export const CANDIDATE_INVITATION = 'CANDIDATE_INVITATION';
export const CANDIDATE_EDIT = 'CANDIDATE_EDIT';

export const CANDIDATE_REPORT = 'CANDIDATE_REPORT';
export const CANDIDATE_FILES = 'CANDIDATE_FILES';
export const CANDIDATES_EXPORT = 'CANDIDATES_EXPORT';
export const CANDIDATES_EXTENDED_EXPORT = 'CANDIDATES_EXTENDED_EXPORT';

export const CLIENT_LIST = 'CLIENT_LIST';
export const CLIENT_ADD = 'CLIENT_ADD';
export const CLIENT_EDIT = 'CLIENT_EDIT';

export const COMPETENCE_LIST = 'COMPETENCE_LIST';
export const COMPETENCE_ADD = 'COMPETENCE_ADD';
export const COMPETENCE_EDIT = 'COMPETENCE_EDIT';

export const COMPETENCY_PROFILE_LIST = 'COMPETENCY_PROFILE_LIST';
export const COMPETENCY_PROFILE_ADD = 'COMPETENCY_PROFILE_ADD';
export const COMPETENCY_PROFILE_EDIT = 'COMPETENCY_PROFILE_EDIT';

export const COMPETENCY_RATING_EDIT = 'COMPETENCY_RATING_EDIT';

export const DASHBOARD = 'DASHBOARD';
export const DOWNLOAD = 'DOWNLOAD';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';

export const FILE = 'FILE';

export const GAME_LIST = 'GAME_LIST';
export const GAME_ADD = 'GAME_ADD';
export const GAME_EDIT = 'GAME_EDIT';

export const GAME_BADGE_LIST = 'GAME_BADGE_LIST';
export const GAME_BADGE_ADD = 'GAME_BADGE_ADD';
export const GAME_BADGE_EDIT = 'GAME_BADGE_EDIT';

export const GAME_FORM_LIST = 'GAME_FORM_LIST';
export const GAME_FORM_ADD = 'GAME_FORM_ADD';
export const GAME_FORM_EDIT = 'GAME_FORM_EDIT';

export const GAME_PAGE_LIST = 'GAME_PAGE_LIST';
export const GAME_PAGE_EDIT = 'GAME_PAGE_EDIT';

export const GAME_STAGE_LIST = 'GAME_STAGE_LIST';
export const GAME_STAGE_ADD = 'GAME_STAGE_ADD';
export const GAME_STAGE_EDIT = 'GAME_STAGE_EDIT';
export const GAME_STAGE_EXPORT = 'GAME_STAGE_EXPORT';

export const LOGIN = 'LOGIN';

export const MANAGEMENT_ANALYST_LIST = 'MANAGEMENT_ANALYST_LIST';

export const MESSAGE_TEMPLATE_LIST = 'MESSAGE_TEMPLATE_LIST';
export const MESSAGE_TEMPLATE_ADD = 'MESSAGE_TEMPLATE_ADD';
export const MESSAGE_TEMPLATE_EDIT = 'MESSAGE_TEMPLATE_EDIT';

export const NOT_FOUND = 'NOT_FOUND';

export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

export const RECRUITMENT_PROCESS_CANDIDATES_EXPORT = 'RECRUITMENT_PROCESS_CANDIDATES_EXPORT';
export const RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT = 'RECRUITMENT_PROCESS_CANDIDATES_EXTENDED_EXPORT';
export const RECRUITMENT_PROCESS_LIST = 'RECRUITMENT_PROCESS_LIST';
export const RECRUITMENT_PROCESS_ADD = 'RECRUITMENT_PROCESS_ADD';
export const RECRUITMENT_PROCESS_EDIT = 'RECRUITMENT_PROCESS_EDIT';

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const USER_LIST = 'USER_LIST';
export const USER_ADD = 'USER_ADD';
export const USER_EDIT = 'USER_EDIT';

export const WORK_DEPARTMENT_LIST = 'WORK_DEPARTMENT_LIST';
export const WORK_DEPARTMENT_ADD = 'WORK_DEPARTMENT_ADD';
export const WORK_DEPARTMENT_EDIT = 'WORK_DEPARTMENT_EDIT';

export const WORK_POSITION_LIST = 'WORK_POSITION_LIST';
export const WORK_POSITION_ADD = 'WORK_POSITION_ADD';
export const WORK_POSITION_EDIT = 'WORK_POSITION_EDIT';
