export default {
  note: 'Note',
  cancel: 'Cancel',
  close: 'Close',
  back: 'Back',
  accept: 'Submit',
  confirm: 'Confirm',
  add: 'Add',
  save: 'Save',
  edit: 'Edit',
  remove: 'Remove',
  clone: 'Duplicate',
  copy: 'Copy',
  copied: 'Copied',
  export: 'Export',
  export_basic: 'Basic',
  export_extended: 'Extended',
  upload_image: 'Upload photo',
  upload_video: 'Upload video',
  upload_image_details: 'Upload the image in the format: %{exts}',
  upload_video_details: 'Upload video (%{exts}, max.%{maxSize} MB)',
  upload_favicon_ext: 'Upload icon in format: .ico',
  tags: 'Tags',
  no_data_available: 'No data',
  not_found: 'Not found',
  press: 'Press',
  to_add: 'to add',
  download: 'download',
  of: 'of',
  table: {
    loading: 'Loading ...',
    pagination_results: 'Results per page',
  },
  topbar: {
    logout: 'Log out',
  },
  sidebar: {
    management: 'Recruitment management',
    admin: 'Administration',
  },
  locale_types: {
    polish: 'Polski',
    english: 'English',
  },
  modal: {
    error: {
      title: 'An Error Occured',
      content: 'Please try again, and if the error persists contact technical support.',
      button: 'Close',
    },
    cancel: {
      title: 'All changes will be lost. Do you want to continue? ',
      confirm: 'Yes',
      cancel: 'No',
    },
    confirm: {
      title: 'Do you want to continue?',
      confirm: 'Yes',
      cancel: 'No',
    },
    invite: {
      title: 'Do you want to send invitations?',
      content: 'You have entered <strong>%{quantity}</strong> %{plural} e-mail correctly in the form.',
      content_for_multiple_candidates: 'The list contains <strong>%{existingCandidatesLength}</strong> Candidates who have already completed this assessment tool - sending a new invitation will reset the results, allowing Candidates to start it again. Applies to:',
      content_for_single_candidate: 'The list contains <strong>1</strong> Candidate who has already completed this assessment tool - sending a new invitation will reset the result, allowing the Candidate to start it again. Applies to:',
      confirm: 'Invite',
      cancel: 'Back',
    },
    change_stage_or_status: {
      title_for_multiple_candidates: 'Do you want to send invitations?',
      title_for_single_candidate: 'Do you want to send invitation?',
      content_for_multiple_candidates: 'The list contains <strong>%{existingCandidatesLength}</strong> Candidates who have already completed this assessment tool - sending a new invitation will reset the results, allowing Candidates to start it again. Applies to:',
      content_for_single_candidate: 'The selected Candidate has already completed this tool - sending a new invitation will reset the results, allowing the Candidate with email address <strong>%{email}</strong> to start it again.',
      confirm: 'Invite',
      cancel: 'Back',
    },
    recruitment_edit: {
      title: 'Link to the recruitment process has been generated',
      content: 'Share it with candidates to enable participation in the recruitment process <br><br> %{registrationUrl}',
      confirm: 'Close',
    },
    remove: {
      title: 'Are you sure you want to delete?',
      content: 'Removal is an irreversible operation.',
      confirm: 'Delete',
      cancel: 'Back',
    },
  },
};
