<template>
  <ExportLoader
    v-if="isLoading"
    :is-loading="isLoading"
  />
</template>
<script>
import alerts from '@/plugins/alerts';
import exportFile from '@/plugins/files/exportFile';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';
import candidatesExtendedExport from '@/components/Dashboard/Candidate/Api/candidatesExtendedExport';
import ExportLoader from '@/components/Dashboard/Partials/Export/ExportLoader';

export default {
  name: 'CandidatesExtendedExport',
  components: {
    ExportLoader,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    await this.generate();
  },
  methods: {
    ...alerts,
    async generate() {
      try {
        this.isLoading = true;
        const { recruitmentProcessId, candidateIds, gameId } = this.$route.query;
        const response = await candidatesExtendedExport({
          recruitmentProcessId,
          candidateIds,
          gameId,
        });
        exportFile(response);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
