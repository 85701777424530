<template>
  <SubMenu
    :name="$t('dashboard.export')"
    :menu-items="menuItems"
    is-search-input-enabled
    @sub-menu-click="menuItemClicked"
  />
</template>
<script>
import SubMenu from '@/components/Dashboard/Partials/SubMenu/SubMenu';

export default {
  name: 'RecruitmentExportMenu',
  components: {
    SubMenu,
  },
  props: {
    hasExportAccess: { type: Boolean, default: false },
    hasExtendedExportAccess: { type: Boolean, default: false },
    games: { type: Array, default: () => [] },
  },
  computed: {
    menuItems() {
      const menuItems = [];

      if (this.hasExportAccess) {
        const exportBasic = {
          name: this.$t('dashboard.export_basic'),
          action: () => {
            this.$emit('export-data');
          },
        };
        menuItems.push(exportBasic);
      }

      if (this.hasExtendedExportAccess && this.games.length) {
        const exportExtended = {
          name: this.$t('dashboard.export_extended'),
          menu: this.games.map(({ name, id }) => ({
            name,
            action: () => {
              this.$emit('export-extended-data', id);
            },
          })),
        };
        menuItems.push(exportExtended);
      }

      return menuItems;
    },
  },
  methods: {
    menuItemClicked(item) {
      if (item.action) {
        item.action();
      }
    },
  },
};
</script>
