export const CANDIDATE_INDEX = 'CANDIDATE_INDEX';
export const CANDIDATE_SHOW = 'CANDIDATE_SHOW';

export const CANDIDATE_RECRUITMENT_PROCESS_DATA_EXPORT = 'CANDIDATE_RECRUITMENT_PROCESS_DATA_EXPORT';
export const CANDIDATE_RECRUITMENT_PROCESS_EXTENDED_DATA_EXPORT = 'CANDIDATE_RECRUITMENT_PROCESS_EXTENDED_DATA_EXPORT';

export const CLIENT_INDEX = 'CLIENT_INDEX';
export const CLIENT_CREATE = 'CLIENT_CREATE';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';

export const COMPETENCE_INDEX = 'COMPETENCE_INDEX';
export const COMPETENCE_CREATE = 'COMPETENCE_CREATE';
export const COMPETENCE_UPDATE = 'COMPETENCE_UPDATE';

export const COMPETENCY_PROFILE_INDEX = 'COMPETENCY_PROFILE_INDEX';
export const COMPETENCY_PROFILE_CREATE = 'COMPETENCY_PROFILE_CREATE';
export const COMPETENCY_PROFILE_UPDATE = 'COMPETENCY_PROFILE_UPDATE';

export const GAME_INVITATIONS_SEND = 'GAME_INVITATIONS_SEND';

export const GAME_INDEX = 'GAME_INDEX';
export const GAME_CREATE = 'GAME_CREATE';
export const GAME_COPY = 'GAME_COPY';
export const GAME_UPDATE = 'GAME_UPDATE';

export const GAME_BADGE_INDEX = 'GAME_BADGE_INDEX';
export const GAME_BADGE_CREATE = 'GAME_BADGE_CREATE';
export const GAME_BADGE_UPDATE = 'GAME_BADGE_UPDATE';

export const GAME_FORM_INDEX = 'GAME_FORM_INDEX';
export const GAME_FORM_CREATE = 'GAME_FORM_CREATE';
export const GAME_FORM_UPDATE = 'GAME_FORM_UPDATE';

export const GAME_PAGE_INDEX = 'GAME_PAGE_INDEX';
export const GAME_PAGE_UPDATE = 'GAME_PAGE_UPDATE';

export const GAME_SESSION_EVENT_LOG_INDEX = 'GAME_SESSION_EVENT_LOG_INDEX';
export const GAME_SESSION_EVENT_LOG_GAME_LIST = 'GAME_SESSION_EVENT_LOG_GAME_LIST';
export const GAME_SESSION_EVENT_LOG_EXPORT_ANALYTICS_DATA = 'GAME_SESSION_EVENT_LOG_EXPORT_ANALYTICS_DATA';

export const GAME_STAGE_INDEX = 'GAME_STAGE_INDEX';
export const GAME_STAGE_CREATE = 'GAME_STAGE_CREATE';
export const GAME_STAGE_UPDATE = 'GAME_STAGE_UPDATE';
export const GAME_STAGE_DATA_EXPORT = 'GAME_STAGE_DATA_EXPORT';

export const MESSAGE_TEMPLATE_INDEX = 'MESSAGE_TEMPLATE_INDEX';
export const MESSAGE_TEMPLATE_CREATE = 'MESSAGE_TEMPLATE_CREATE';
export const MESSAGE_TEMPLATE_UPDATE = 'MESSAGE_TEMPLATE_UPDATE';

export const ICON_INDEX = 'ICON_INDEX';

export const RECRUITMENT_PROCESS_INDEX = 'RECRUITMENT_PROCESS_INDEX';
export const RECRUITMENT_PROCESS_CREATE = 'RECRUITMENT_PROCESS_CREATE';
export const RECRUITMENT_PROCESS_UPDATE = 'RECRUITMENT_PROCESS_UPDATE';
export const RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA = 'RECRUITMENT_PROCESS_EXPORT_CANDIDATE_DATA';
export const RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA = 'RECRUITMENT_PROCESS_EXTENDED_EXPORT_CANDIDATE_DATA';

export const USER_INDEX = 'USER_INDEX';
export const USER_SHOW = 'USER_SHOW';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

export const WORK_DEPARTMENT_INDEX = 'WORK_DEPARTMENT_INDEX';
export const WORK_DEPARTMENT_CREATE = 'WORK_DEPARTMENT_CREATE';
export const WORK_DEPARTMENT_UPDATE = 'WORK_DEPARTMENT_UPDATE';

export const WORK_POSITION_INDEX = 'WORK_POSITION_INDEX';
export const WORK_POSITION_CREATE = 'WORK_POSITION_CREATE';
export const WORK_POSITION_UPDATE = 'WORK_POSITION_UPDATE';
